import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';

import media from 'utils/mediaqueries';

import Seo from 'components/container/Seo';
import PageBuilder from 'components/container/PageBuilder';

export const InfoPageWrapperStyled = styled('div')({
  [media('tabletFluid')]: {
    paddingTop: 60,
  },
});

export default function TemplateInfoPages({ data, pageContext }) {
  const url = pageContext?.url;
  const { backgroundImage } = data;
  const {
    title,
    pageBuilderNeo,
    seoTitle,
    seoDescription,
    seoImage,
  } = data?.craft?.entry;

  return (
    <>
      <Seo
        title={seoTitle || title}
        description={seoDescription}
        url={url}
        image={seoImage?.[0]?.socialMediaUrl}
      />
      <InfoPageWrapperStyled>
        <PageBuilder
          components={pageBuilderNeo}
          backgroundImage={backgroundImage}
        />
      </InfoPageWrapperStyled>
    </>
  );
}

export const query = graphql`
  query QueryInfoPage($id: [Int]!, $siteId: Int) {
    craft {
      entry(id: $id, siteId: $siteId) {
        id
        title
        slug
        ... on Craft_InfoPages {
          seoTitle
          seoDescription
          seoImage {
            socialMediaUrl: url(transform: transformSocialMedia)
          }
          pageBuilderNeo {
            ...PageBuilderQuery
          }
        }
      }
    }
    backgroundImage: file(relativePath: { eq: "bg-2.jpg" }) {
      ...imageDefault
    }
  }
`;
